@charset "UTF-8";
.us-content p {
  text-indent: 2em;
  margin: 0 15px;
  line-height: 3em;
}

@media (max-width: 768px) {
  .us-content img {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .us-content {
    column-count: 1;
  }
}

.color-section {
  background: #080058;
  min-height: 400px;
  padding: 80px 50px;
  overflow: auto;
}

.color-section h1, .color-section h2, .color-section h3, .color-section h4, .color-section h5, .color-section h6, .color-section a, .color-section span, .color-section p {
  color: white;
}

.color-section h1 {
  font-size: 3.5em;
  margin: 1em 0;
}

.color-section .describe {
  width: 60%;
  margin-left: 20%;
}

@media (max-width: 768px) {
  .color-section h1 {
    font-size: 2.5em;
  }
  .color-section .describe {
    width: 95%;
    margin-left: 2.5%;
  }
}

#about-depart .carousel-inner .item .dp-card {
  height: 300px;
  padding: 20px 80px;
}

.workstyle-carousel {
  width: 100%;
  min-height: 300px;
  background-size: cover;
}

@media (max-width: 500px) {
  .workstyle-carousel {
    min-height: 200px;
  }
}

.basic-info {
  width: 100%;
  text-align: left;
  margin-bottom: 80px;
}

.basic-info h3 {
  font-weight: 600;
}

.basic-info p {
  margin: 0;
}

/* -----------------------------
    XiQian and JiZhi
-------------------------------*/
h3.question {
  color: red;
}

.fund-manager .media {
  margin: 20px 0;
}

.fund-manager .media .avatar {
  width: 120px;
  height: 160px;
  border-radius: 5px;
}

.fund-manager .media-heading {
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
  font-size: 1.6em;
  font-weight: 500;
  margin: 5px 0;
}

.fund-manager .media p {
  font-size: 14px;
  text-indent: 2em;
}
